<script>

    import ProfileSidebar from "./ProfileSidebar";

    import {mapState} from "vuex";

    /**
     * Profile component
     */
    export default {
        components: {ProfileSidebar},
        data() {
            return {
                tag: {
                    type: null,
                    tagId: null,
                    text: ''
                }

            }
        },

      computed:{
          ...mapState({
            user: state => state.auth.currentUser,
            athleteTags: state => state.tags._athleteTags,
            dpTags: state => state.tags._dietPlanTags,
            mealTemplateTags: state => state.tags._mealTemplateTags
          })
      },
        methods: {

            async fetchDPTags(){
                if(!this.$store.state.tags._dietPlanTags) {
                    await this.$store.dispatch('tags/fetchCacheDietPlanTags');
                }else{
                    await this.$store.dispatch('tags/refreshDPTagsCache');
                }
            },

            async fetchUserAthleteTags(){
                if(!this.$store.state.tags._athleteTags) {
                    await this.$store.dispatch('tags/fetchCacheAthleteTags');
                }else{
                    await this.$store.dispatch('tags/refreshAthleteTagsCache');
                }
            },

            toggleEditTagModal(tag, type){
                this.tag.type = type;
                this.tag.tagId = tag.id;
                this.tag.text = tag.text;
                this.$refs['edit-tag'].show();
            },

           async updateTag(){

                let id = this.tag.tagId;
                let text = this.tag.text;

                if(this.tag.type === 'athlete'){
                    await this.$store.dispatch('tags/updateAthleteTag', {id: id, text: text});
                }

                if(this.tag.type === 'dietPlan'){
                    await this.$store.dispatch('tags/updateDietPlanTag', {id: id, text: text});
                }

                if(this.tag.type === 'mealTemplate'){
                  await this.$store.dispatch('tags/updateMealTemplateTag', {id: id, text: text});
                }


               this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Η ετικέτα ενημερώθηκε επιτυχώς.!'});
               this.$refs['edit-tag'].hide();

            },

            async deleteTag(){
              this.$dialog.confirm({
                title:  this.$t('verifyDeletion.title'),
                body: this.$t('verifyDeletion.message')
              }, {
                html: true,
                loader: true,
                okText: this.$t('verifyDeletion.ok'),
                cancelText:  this.$t('verifyDeletion.cancel'),
                backdropClose: true
              }).then(async (dialog) => {

                let id = this.tag.tagId;

                if (this.tag.type === 'athlete') {
                  await this.$store.dispatch('tags/removeAthleteTag', id);
                }

                if (this.tag.type === 'dietPlan') {
                  await this.$store.dispatch('tags/removeDietPlanTag', id);
                }

                if(this.tag.type === 'mealTemplate'){
                  await this.$store.dispatch('tags/removeMealTemplateTag', id);
                }
                this.$refs['edit-tag'].hide();
                dialog.close();

              }).catch(()=>{});
            }
        }
    }
</script>
<style lang="scss">
    .tag-btn {
        padding: 0.3rem 0.6rem !important;
        margin-right: 7px;
        margin-bottom: 7px;
    }
    .delete-tag{
        margin-right:5px;
    }
</style>
<template>
    <div id="user-tags-layout">
        <div class="row">
            <ProfileSidebar></ProfileSidebar>
            <div class="col-lg-9 col-xl-9">
                <div class="card">

                    <b-tabs>
                        <b-tab title="Ετικέτες αθλητών" active>
                            <h5 class="mb-3 text-uppercase bg-light p-2"><i class="fe-tag"></i> ΕΤΙΚΕΤΕΣ ΑΘΛΗΤΩΝ</h5>
                            <span v-for="atTag in athleteTags" :key="atTag.id">
                                <b-button variant="outline-primary" class="tag-btn"  @click="toggleEditTagModal(atTag, 'athlete')"><i class="fe-tag"></i> {{atTag.text}}</b-button>
                            </span>
                        </b-tab>
                        <b-tab title="Ετικέτες διατροφικών πλάνων">
                            <h5 class="mb-3 text-uppercase bg-light p-2"><i class="fe-tag"></i> ΕΤΙΚΕΤΕΣ ΔΙΑΤΡΟΦΙΚΩΝ ΠΛΑΝΩΝ</h5>
                            <span v-for="dpTag in dpTags" :key="dpTag.id">
                                <b-button variant="outline-primary" class="tag-btn"  @click="toggleEditTagModal(dpTag, 'dietPlan')"><i class="fe-tag"></i> {{dpTag.text}}</b-button>
                            </span>
                        </b-tab>
                      <b-tab title="Ετικέτες πρότυπων γευμάτων">
                        <h5 class="mb-3 text-uppercase bg-light p-2"><i class="fe-tag"></i> ΕΤΙΚΕΤΕΣ ΠΡΟΤΥΠΩΝ ΓΕΥΜΑΤΩΝ</h5>
                        <span v-for="dpTag in mealTemplateTags" :key="dpTag.id">
                                <b-button variant="outline-primary" class="tag-btn"  @click="toggleEditTagModal(dpTag, 'mealTemplate')"><i class="fe-tag"></i> {{dpTag.text}}</b-button>
                            </span>
                      </b-tab>
                    </b-tabs>
                </div>
                <!-- end card-box-->
            </div>

            <!-- end col -->
        </div>

        <b-modal id="edit-tag" ref="edit-tag" size="medium" title="Επεξεργασία ετικέτας" hide-footer body-class="p-3">
            <div class="row">
                <div class="col-md-12">
                    <b-form-group label="Όνομα ετικέτας:" label-for="edit-tag">
                        <b-input-group>
                            <b-form-input id="edit-tag" v-model="tag.text" type="text" ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </div>
            </div>

            <b-form-group class="action-btns text-right">

                <delete-button class="mr-2" @click="deleteTag"></delete-button>
                <save-button @click="updateTag"></save-button>
            </b-form-group>

        </b-modal>
        <!-- end row-->
    </div>
</template>
